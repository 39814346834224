<template>
  <a-list
    itemLayout="horizontal"
    :dataSource="data"
  >
    <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
      <a-list-item-meta>
        <a slot="title">{{ item.title }}</a>
        <span slot="description">
          <span class="security-list-description">{{ item.description }}</span>
          <span v-if="item.value"> : </span>
          <span class="security-list-value">{{ item.value }}</span>
        </span>
      </a-list-item-meta>
      <template v-if="item.actions">
        <a slot="actions" @click="item.actions.callback">{{ item.actions.title }}</a>
      </template>

    </a-list-item>
    
    <a-modal title='修改密码' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='当前密码' prop='oldPassword'>
          <a-input-password v-model="deliver1.form.oldPassword"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label='新密码' prop='password'>
          <a-input-password v-model="deliver1.form.password"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label='确认密码' prop='confirmPassword'>
          <a-input-password v-model="deliver1.form.confirmPassword"></a-input-password>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-list>
</template>

<script>
import { changePassword } from '@/api/login'
import md5 from 'md5'

export default {
  data() {
    return {
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          oldPassword: [{ required: true, message: '请输入原密码', trigger: 'change' }],
          password: [{ required: true, message: '请输入新密码', trigger: 'change' }],
          confirmPassword: [
            { required: true, message: '请输入新密码', trigger: 'change' },
            { validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入新密码'));
              } else if (value !== this.deliver1.form.password) {
                callback(new Error("两次输入的密码不一样"));
              } else {
                callback();
              }
            }, trigger: 'change' },
          ],
        },
      },
    }
  },
  computed: {
    data () {
        return [
        { title: this.$t('account.settings.security.password'), description: this.$t('account.settings.security.password-description'), value: '强', actions: { title: this.$t('account.settings.security.modify'), callback: () => { this.initialDeliver1() } } },
        { title: this.$t('account.settings.security.phone'), description: this.$t('account.settings.security.phone-description'), value: '138****8293', actions: { title: this.$t('account.settings.security.modify'), callback: () => { this.$message.success('This is a message of success') } } },
        { title: this.$t('account.settings.security.question'), description: this.$t('account.settings.security.question-description'), value: '', actions: { title: this.$t('account.settings.security.set'), callback: () => { this.$message.error('This is a message of error') } } },
        { title: this.$t('account.settings.security.email'), description: this.$t('account.settings.security.email-description'), value: 'ant***sign.com', actions: { title: this.$t('account.settings.security.modify'), callback: () => { this.$message.warning('This is message of warning') } } },
        { title: this.$t('account.settings.security.mfa'), description: this.$t('account.settings.security.mfa-description'), value: '', actions: { title: this.$t('account.settings.security.bind'), callback: () => { this.$message.info('This is a normal message') } } }
      ]
    }
  },
  methods: {
    initialDeliver1(record) {
      this.deliver1.form = {};
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          changePassword({
            oldPassword: md5(this.deliver1.form.oldPassword),
            password: md5(this.deliver1.form.password),
          }).then(_result => {
            this.$message.success('操作成功')
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
